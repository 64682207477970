import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useAllOrder() {
    const headers = [
        { text: '订单类型', value: 'order_type', sortable: false },
        { text: '用户', value: 'username', sortable: false },
        { text: '用户手机', value: 'mobile', sortable: false },
        { text: '司机', value: 'driver_name', sortable: false },
        { text: '司机手机', value: 'driver_mobile', sortable: false },
        { text: '车辆', value: 'car_no', width: 110, sortable: false },
        { text: '出发地', value: 'start_address', sortable: false },
        { text: '纬度', value: 'start_lat', sortable: false },
        { text: '经度', value: 'start_lng', sortable: false },
        { text: '目的地', value: 'end_address', sortable: false },
        { text: '纬度', value: 'end_lat', sortable: false },
        { text: '经度', value: 'end_lng', sortable: false },
        { text: '状态', value: 'state', sortable: false },
        { text: '下单时间', value: 'create_at', sortable: false },
        // { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        query: '',
        options: { page: 1, itemsPerPage: 10 },
        state: "99",
    })
    const table = ref({
        list: [],
        total: 0,
        snack: {
            open: false,
            msg: ''
        },
        loading: false,
        orderTypes: { // 1=实时  2=回程   3=预约
            "1": '实时',
            "2": '回程',
            "3": '预约',
        },
        status: { // 1=订单完成 11=用户取消 12=超时自动取消 96=送达乘客 97=接到乘客 98=司机接单 99=提交订单
            "": "全部",
            "1": "订单完成",
            "2": "订单超时取消",
            "11": "用户取消",
            "12": "司机取消",
            "96": "送达乘客",
            "97": "接到乘客",
            "98": "司机接单",
            "99": "提交订单",
        }
    })
    const fetchAllOrderList = () => {
        table.value.loading = true
        store.dispatch('order-taxi-wait/fetchAllOrderList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snack = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }
    watch([param], () => {
        fetchAllOrderList()
    }, { deep: true })
    return {
        headers,
        param,
        table,
        fetchAllOrderList
    }
}
